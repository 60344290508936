<template lang="pug">
  li.CardDate.bg-white.rounded-xl.shadow-bx-small-sm.p-3.my-5
    .flex.relative
      .w-16.h-16.bg-primary.rounded-xl(v-if="date && !date.src")
      img(:data-src="date.src" :alt="date.place" ref="imgRef" loading="lazy" v-else).w-16.h-16.bg-primary.rounded-xl.lazy-loading
      article.ml-3.w-full.maxarticle
        h4.text-primary.font-semibold.text-sm.truncate.w-full.max-w-full.relative
          | {{ date.name }}
          div(v-if="acordionNoShow && !noEdit && !date.removeEditDrugstore" @click="() => changeTrip('Home_appointments')").absolute.orientation-dk
            button.text-good_color
              small.text-lg.material-icons-outlined edit
        p.text-info_color.truncate.w-full.max-w-full.text-sm {{ date.address || "No hay dirección" }}
        p.text-info_color.truncate.w-full.max-w-full.text-sm {{ date.phone ? "Tel. " + date.phone : "No hay telefono" }}
    hr.opacity-20.mt-2.mb-1
    acordion(title="Entregas a despachar" v-if="!acordionNo")
      article.m-0
        p.mx-1: small.text-info_color
          | En este dispensario te podemos entregar
        .flex.dkmaxwidth100
          p.small-p: small.mx-1.text-secondary(v-for="(numb, i) in date.numbers" :key="i") 
            label(v-if="numb != 9999999999999") {{numb}}
            label(v-else) No se especifico una formula
          //- p.small-p: small.text-xs.small-text.mx-1.text-secondary 125689
    div(v-else)
      div(v-if="!acordionNoShow").flex.items-center.justify-between
        .inline-block.mr-3
          div(v-if="!adjunto").mx-auto.Arrow.rounded-bd-complete.w-10.h-10.flex.items-center.justify-center.border-none.outline-none.opacity-50
            span(class="material-icons-outlined").text-primary place
          div(v-else).mx-auto.Arrow.rounded-bd-complete.w-10.h-10.flex.items-center.justify-center.border-none.outline-none.opacity-50
            span(class="material-icons-outlined").text-primary
              img(src="@/assets/images/pill_icon.svg")
        article.m-0.w-full
          p.mx-1: small.text-info_color
            | Aqui podemos entregar las ordenes
            .flex.mt-3.dkmaxwidth
              p: small.mx-1.text-secondary(v-for="(numb, i) in date.numbers" :key="i") 
                label(v-if="numb != 9999999999999") {{numb}}
                label(v-else) No se especifico una formula
      div(v-else)
        slot
    .w-full.flex.justify-end
      .maxwidthbutton
        button-component(
          text="Agendar cita"
          themeSelected="primaryOther"
          size="text-xs p-2 rounded-bd-small"
          :onClick="handleGoToDate"
          v-if="!acordionNo"
        )
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import loadingMessage from "../utils/loadingMessage";

export default {
  name: "CardDate",
  components: {
    ButtonComponent: () => import("./general/Button.vue"),
    Acordion: () => import("./general/Acordion.vue"),
  },
  props: {
    date: Object,
    acordionNo: Boolean,
    adjunto: Boolean,
    acordionNoShow: Boolean,
    noEdit: Boolean,
  },
  mounted() {
    if ("loading" in HTMLImageElement.prototype) {
      if (this.$refs.imgRef) {
        this.$refs.imgRef.src = this.$refs.imgRef.dataset.src;
      }
    }
  },
  computed: {
    ...mapState({
      env: (state) => state.environment.environment.env,
      closeModal: (state) => state.root.closeModal
    }),
  },
  methods: {
    ...mapActions("root", [
      "changeTrip", 
      "handleShowModal",
    ]),
    ...mapActions(["fetchSchedule"]),
    ...mapMutations("drugstore", {
      setStateDrugstore: "setState",
    }),
    async handleGoToDate() {
      
      // fetch schedule
      this.handleShowModal(loadingMessage({
        title: "Consultando agendamiento...",
        state: "loading",
      }));
      
      await this.fetchSchedule({
        url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
        headers: {
          'token_appointments': this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY,
        },
        queryData: '?branchId=' + this.date.branch_id
      });
      
      this.closeModal();

      this.setStateDrugstore({ key: "drugstoreState", value: this.date, });
      this.changeTrip("Drugstore");
    },
  },
}
</script>

<style scoped>
  .small-text {
    font-size: 0.7rem;
    cursor: pointer;
  }
  .small-p {
    margin-top: -7px;
  }
  .maxwidthbutton {
    max-width: 90px;
    margin-top: -5px;
  }
  .maxarticle {
    max-width: 78.5%;
  }
  .orientation-dk {
    right: -2px;
    top: -2px;
    background: white;
  }
  .dkmaxwidth {
    max-width: 80%;
    overflow-x: auto;
  }
  .dkmaxwidth100 {
    max-width: 100%;
    overflow-x: auto;
  }
</style>